import { css } from '@emotion/core';

import { color } from './mixins.styles';
import { mqFrom, mqTo } from './responsive.styles';
import { typescale } from './typescale.styles';
import {
  colors,
  fontFamily,
  fontSize,
  fontWeights,
  lineHeight,
  unit,
} from './vars.styles';

const wrapper = css`
  padding: 0 10px;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;

  transition: all 0.5s;

  ${mqFrom.Med} {
    padding: 0 40px;
  }

  ${mqFrom.Lrg} {
    padding: 0 80px;
  }
`;

const pageWrapper = css`
  min-height: 100vh;
`;

const aside = css`
  height: 100vh;
  width: 240px;
`;

const main = css`
  position: relative;
  min-height: calc(100vh - 125px - 140px);
  display: flex;
  flex-direction: column;
  margin-top: 110px;

  ${mqFrom.Med} {
    margin-top: 190px;
  }
`;

const mainNoEmailVerificationMessage = css`
  position: relative;
  min-height: calc(100vh - 125px - 140px);
  display: flex;
  flex-direction: column;
  margin-top: 88px;
`;

const mainFixedHeight = css`
  height: calc(100vh - 220px);
`;

const header = css`
  height: 80px;
  border-bottom: 1px solid ${color('#000000', 0.1)};
`;

const headerNone = css`
  display: none;
`;

const headerFlex = css`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const nav = css`
  display: flex;
  align-items: center;
  height: 100%;
`;

const navUser = css`
  display: flex;
  align-items: center;
  margin-top: 16px;

  margin-right: 0;

  ${mqFrom.XSml} {
    margin-right: 50px;
  }

  ${mqFrom.Lrg} {
    margin-right: 0;
  }

  ${mqTo.XSml} {
    width: 100%;
    justify-content: center;
  }

  ${mqTo.Xxsml} {
    padding: 0 10px;
    justify-content: flex-start;
  }
`;

const navUserBtn = css`
  display: flex;
  padding: ${unit * 6}px ${unit * 3}px;

  &:hover {
    cursor: pointer;
    svg {
      path {
        stroke: #b30000;
      }
    }
  }
`;

const navUserWrapper = css`
  position: relative;
`;

const navUserWrapperAfter = css`
  &:after {
    position: absolute;
    bottom: 9px;
    right: 16px;
    content: ' ';
    width: 10px;
    border-top: 1px solid ${colors.gray};
    border-right: 1px solid ${colors.gray};
    height: 10px;
    background: ${colors.bg};
    transform: rotate(-45deg);
  }
`;

const subnav = css`
  position: fixed;
  top: 89px;
  left: 0;
  width: 100%;
  height: 0px;
  padding-top: 52px;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  background: white;
  border-bottom: 1px solid ${color('#000000', 0.1)};

  transition: all 0.3s ease-out;

  opacity: 0;
  visibility: hidden;

  ${mqTo.Lrg} {
    display: none;
  }
`;

const subnavVisible = css`
  position: fixed;
  visibility: visible;
  opacity: 1;
  height: 360px;
  z-index: 2;
`;

const subnavCol = css`
  display: flex;
  flex-direction: column;
  width: 220px;
  font-size: 14px;
  line-height: 36px;
  align-items: center;
`;

const footer = css`
  ${mqFrom.Med} {
    height: 140px;
    padding-top: 32px;
    padding-bottom: 24px;
    border-top: 1px solid ${color('#000000', 0.1)};
  }
`;

const footerNone = css`
  ${headerNone}
`;

const breadcrumbs = css`
  display: none;
  ${mqFrom.Med} {
    height: 64px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

const breadcrumbsNoEmailVerificationMessage = css`
  ${breadcrumbs};
  ${mqFrom.Med} {
    margin-top: 16px;
  }
`;

const popup = css`
  position: fixed;
  left: 0;
  width: 100%;
  top: 72px;
  border-radius: 0px;
  border: none;
  border-radius: 4px;
  padding: ${unit * 8}px;
  border: 1px solid ${colors.gray};
  background: ${colors.bg};
  display: flex;
  flex-direction: column;
  cursor: default;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.1);

  ${mqFrom.Sml} {
    position: absolute;
    left: auto;
    top: 52px;
    right: -3px;
    width: 300px;
  }
`;

const popupItemWrapper = css`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  & > * + * {
    margin-top: 12px;
  }
`;

const cartAmountWrapper = css`
  border-radius: 50%;
  background-color: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  height: 30%;
  position: absolute;
  top: 10px;
  right: -3px;
  width: 30%;

  border: 1px solid ${colors.bg};
  padding: 10px;
`;

const cartAmount = css`
  color: ${colors.bg};
  font-family: ${fontFamily.sans};
  font-weight: ${fontWeights.weightRegular};
  font-size: 11px;
  line-height: 10px;
`;

const hidden = css`
  display: none;
  opacity: 0;
  transition: all 0.6s linear;
`;

const searchInput = css`
  border: none;
  height: 35px;
  width: 100%;
  padding: 2px 10px 2px 10px;
  outline: 0;

  font-family: ${fontFamily.sans};
  font-size: ${fontSize.medium}px;
  line-height: ${lineHeight.medium}px;

  color: ${colors.textSecondary};
  margin: 0 ${unit * 2}px;
`;

const overlay = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
  background-color: ${colors.secondary};
  opacity: 0.5;

  cursor: default;
`;

const menuItem = css`
  width: 100%;
  border-radius: 2px;
  padding: 8px 12px;

  &:hover {
    background-color: rgba(179, 0, 0, 0.08);
    cursor: pointer;
  }

  ${typescale.small};
  font-family: ${fontFamily.sans};
  color: ${colors.dark};
`;

const category = css`
  ${menuItem};
  font-weight: ${fontWeights.weightBold};
  text-transform: uppercase;
`;

const model = css`
  ${menuItem};
  font-weight: ${fontWeights.weightRegular};
`;

const popupBtn = css`
  ${model};
  ${typescale.small};
  font-family: ${fontFamily.sans};
  font-style: normal;
  font-weight: ${fontWeights.weightRegular};
  color: ${colors.dark};
  padding: 0;
  transition: color 0.15s ease-out;
  padding: 8px 12px;

  &:hover {
    cursor: pointer;
    color: ${colors.primary};
  }
`;

const searchWrapper = css`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 ${unit * 3}px;
`;

const closeSearchWrapper = css`
  background: none;
  border: none;
  cursor: pointer;

  &:hover,
  active {
    outline: none;
    svg {
      path {
        fill: ${colors.primary};
      }
    }
  }
`;

const searchOverlay = css`
  width: 100%;
  height: 100%;
  background-color: ${colors.bg};
  opacity: 0.2;
  min-height: calc(100vh - 125px - 140px);
  margin-top: 87px;
`;

const cartDesktop = css`
  ${mqTo.Med} {
    display: none;
  }
`;

const cartMobile = css`
  ${mqFrom.Med} {
    display: none;
  }
`;

const activeSearchLayout = css`
  pointer-events: none;
`;

const emailAccept = css`
  z-index: 1;
`;

export const layout = {
  pageWrapper,
  wrapper,
  aside,
  main,
  mainFixedHeight,
  header,
  headerNone,
  headerFlex,
  nav,
  navUser,
  navUserBtn,
  subnav,
  subnavVisible,
  subnavCol,
  footer,
  footerNone,
  breadcrumbs,
  popup,
  popupBtn,
  cartAmount,
  cartAmountWrapper,
  hidden,
  searchInput,
  overlay,
  category,
  model,
  navUserWrapper,
  navUserWrapperAfter,
  searchWrapper,
  closeSearchWrapper,
  searchOverlay,
  cartDesktop,
  cartMobile,
  popupItemWrapper,
  activeSearchLayout,
  emailAccept,
  mainNoEmailVerificationMessage,
  breadcrumbsNoEmailVerificationMessage,
};
